import Button from "react-bootstrap/Button";
import { Col, Container, FormControl, InputGroup, Row } from "react-bootstrap";
import "./App.css";
import launchingSoon from "./assests/Launching soon illustration.png";
import logo from "./assests/Logo white.png";
import { motion } from "framer-motion";

function App() {
  const animateVariant = {
    hidden: {
      x: "-100vw",
    },

    visible: {
      x: 0,
      transition: {
        duration: 1.8,
        ease: "easeInOut",
      },
    },
  };

  return (
    <>
      <Container style={{ color: "white", paddingTop: "55px", width: '201vw' }}>
        <motion.div
          variants={animateVariant}
          initial="hidden"
          animate="visible"
        >
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <motion.img
                variants={animateVariant}
                initial="hidden"
                animate="visible"
                src={logo}
                style={{ width: "160px", marginBottom: "50px" }}
                alt=""
              />
              <h1
                style={{
                  fontSize: "8rem",
                  lineHeight: "0.7",
                  marginBottom: "35px",
                  letterSpacing: "-7px",
                  position: 'relative'
                }}
              >
                Launching soon
              </h1>
              <motion.p
                style={{ marginBottom: "50px" }}
                className="inter_input"
                variants={animateVariant}
                initial="hidden"
                animate="visible"
              >
                your streamlined solution for organizing, storing, and accessing
                documents securely in one place. Our platform is designed to
                simplify your workflow, enhance collaboration, and ensure that
                important files are always just a click away. Stay tuned for the
                launch and get ready to experience a smarter way to manage your
                documents!"
              </motion.p>
              <motion.div
                variants={animateVariant}
                initial="hidden"
                animate="visible"
              >
                <InputGroup style={{ marginBottom: "30px" }}>
                  <FormControl
                    placeholder="Enter your email address"
                    className="inter_input rounded-pill"
                    style={{
                      position: "relative",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  />
                  <Button
                    className="inter_input rounded-pill"
                    style={{
                      position: "absolute",
                      right: 0,
                      zIndex: 10,
                      margin: "2px",
                      height: "90%",
                      backgroundColor: "black",
                      border: "none",
                    }}
                  >
                    Notify me
                  </Button>
                </InputGroup>
              </motion.div>
              <motion.strong style={{ letterSpacing: "6px" }}>
                GET NOTIFIED WHEN WE LAUNCH
              </motion.strong>
            </Col>

            <Col lg={6} md={6} className="d-none d-sm-block" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <motion.img
                variants={animateVariant}
                initial="hidden"
                animate="visible"
                src={launchingSoon}
                className="coming_soon_image"
                style={{ width: '100%' }}
                alt=""
              />
            </Col>
          </Row>
        </motion.div>
      </Container>
    </>
  );
}

export default App;
